<template>
  <tr class="heighRow">
    <td class="text-right">
      <input
        v-if="edit"
        v-model="itemChoice.minValue"
        placeholder="VD: 1.000"
        class="w-100 h-90 input text-right"
        style="padding: 5px"
        v-mask="mask"
      />
      <span class="w-100 text-right" v-else>{{
        formatMoney(item.minValue)
      }}</span>
    </td>
    <td class="text-right">
      <input
        v-if="edit"
        v-model="itemChoice.maxValue"
        placeholder="VD: 1.000.000"
        class="w-100 h-90 input text-right"
        style="padding: 5px"
        v-mask="mask"
      />
      <span class="w-100 text-right" v-else>{{
        formatMoney(item.maxValue)
      }}</span>
    </td>

    <td class="type">
      <b-form-select
        size="sm"
        id="input-1"
        v-model="itemChoice.pointType"
        :options="listType"
        required
        value-field="id"
        text-field="name"
      >
        <template v-slot:first>
          <b-form-select-option :value="null" disabled
            >Chọn kiểu tích điểm</b-form-select-option
          >
        </template>
      </b-form-select>
    </td>
    <td class="text-right">
      <input
        v-if="edit"
        v-model="itemChoice.value"
        placeholder="Nhập giá trị"
        class="w-100 h-90 input text-right"
        style="padding: 5px"
        :max="100"
      />
      <span v-else class="text-right">{{ item.value }}</span>
    </td>
    <td>
      <template v-if="edit">
        <div class="d-flex justify-content-center">
          <v-icon class="mr-2 text-primary" small @click="updateItem()"
            >mdi-check</v-icon
          >
          <v-icon class="mr-2 text-danger" small @click="cancelEdit()"
            >mdi-close</v-icon
          >
        </div>
      </template>

      <template v-else>
        <div class="d-flex justify-content-center">
          <v-icon
            small
            class="mr-2 text-primary"
            @click="editItem()"
            v-b-tooltip
            title="Cập nhật"
            >mdi-pencil</v-icon
          >
          <v-icon
            small
            class="mr-2 text-danger"
            @click="showDeleteAlert"
            v-b-tooltip
            title="Xóa"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </td>
  </tr>
</template>

<style>
.heighRow {
  height: 40px;
}
.textWidth {
  width: 70%;
}
.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
}
th.pointType {
  width: 10%;
}
th.maxValue {
  width: 25%;
}
th.minValue,
th.value {
  width: 25%; /* Not necessary, since only 70% width remains */
}
</style>

<script>
import Swal from 'sweetalert2';
import { currencyMask, unMaskPrice } from './../../utils/common';

export default {
  props: ['item', 'listFeature'],
  data() {
    return {
      edit: false,
      itemChoice: {
        id: 0,
        minValue: 0,
        maxValue: 0,
        pointType: 1,
        value: 0,
      },
      listType: [
        {
          id: 1,
          name: 'Theo phần %',
        },
      ],
      mask: currencyMask,
    };
  },
  updated() {
    this.checkAddRow();
  },
  methods: {
    editItem() {
      this.edit = true;
      this.itemChoice.id = this.item.id;
      this.itemChoice.minValue = this.item.minValue;
      this.itemChoice.maxValue = this.item.maxValue;
      this.itemChoice.value = this.item.value;
      this.itemChoice.pointType = this.item.pointType;
    },
    cancelEdit: function () {
      this.edit = false;
      if (this.item.minValue === 0) {
        this.$emit('cancelAdd');
      }
      this.itemChoice.id = 0;
      this.itemChoice.minValue = 0;
      this.itemChoice.maxValue = 0;
      this.itemChoice.pointType = 1;
    },
    updateItem: function () {
      this.edit = false;
      if (
        this.item.minValue == 0 &&
        this.item.maxValue == 0 &&
        this.item.value == 0
      ) {
        this.itemChoice.id = this.item.id;
        this.item.minValue = unMaskPrice(this.itemChoice.minValue);
        this.item.maxValue = unMaskPrice(this.itemChoice.maxValue);
        this.item.value = this.itemChoice.value;
        this.item.pointType = this.itemChoice.pointType;
        this.$emit('addItem', this.itemChoice);
      } else {
        this.$emit('updateItem', this.itemChoice);
      }
      this.itemChoice.pointType = 1;
      this.itemChoice.minValue = 0;
      this.itemChoice.maxValue = 0;
      this.itemChoice.value = 0;
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: 'Xóa giá trị chương trình tích điểm!',
        text: 'Bạn có chắc muốn xóa giá trị hương trình tích điểm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.item.id);
        }
      });
    },
    checkAddRow() {
      if (
        this.item.minValue == 0 &&
        this.item.maxValue == 0 &&
        this.item.value == 0
      ) {
        this.edit = true;
      } else if (this.edit) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
  },
};
</script>
